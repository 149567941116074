<template>
  <div class="loghi">
    <div class="image-group">
      <h2>Diamond Partner</h2>
      <div class="grid justify-content-center gap-8">
        <img src="@/assets/images/loghi/stock.png" alt="">
        <img src="@/assets/images/loghi/martini.png" alt="">
        <img src="@/assets/images/loghi/branca.png" alt="">
        <img src="@/assets/images/loghi/gruppo-montenegro.png" alt="">
      </div>
    </div>
    <div class="image-group">
      <h2>Gold Partner</h2>
      <div class="grid justify-content-center gap-8">
        <img src="@/assets/images/loghi/carpano.png" alt="">
        <img src="@/assets/images/loghi/ginto.png" alt="">
        <img src="@/assets/images/loghi/winedora.png" alt="">
        <img src="@/assets/images/loghi/borghetti.png" alt="">
        <img src="@/assets/images/loghi/fernet-branca.png" alt="">
        <img src="@/assets/images/loghi/taggiasco.png" alt="">
        <img src="@/assets/images/loghi/antica-formula.png" alt="">
        <img src="@/assets/images/loghi/select.png" alt="">
        <img src="@/assets/images/loghi/punt-mes.png" alt="">
        <img src="@/assets/images/loghi/distillerie-subalpine.png" alt="">
        <img src="@/assets/images/loghi/barogin.png" alt="">
        <img src="@/assets/images/loghi/bugin.png" alt="">
        <img src="@/assets/images/loghi/gin-evrine.png" alt="">
        <img src="@/assets/images/loghi/gargano.png" alt="">
      </div>
    </div>
    <div class="image-group">
      <h2>Produce by</h2>
      <div class="grid justify-content-center gap-8">
        <img src="@/assets/images/loghi/bonobo.png" alt="" style="max-width: none;aspect-ratio: 2">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

.loghi {
  max-width: 1000px;
  margin: 0 auto;
}

.image-group {
  padding: 2rem 2rem;

  h2 {
    padding: 1rem 0;
  }

  img {
    object-fit: contain;
    aspect-ratio: 1;
    max-width: 136px;
  }
}

h2 {
  text-align: center;
  color: black;
}

</style>
<script setup lang="ts">
</script>
